

.swal2-confirm .swal2-styled{
    background-color: red;
  }
  
  .container-1-user {
    display: grid;
    width: 100%;
    grid-template-columns: 4fr 1fr 2fr 2fr;
    grid-template-areas: "content1 content1 content2 content3";
    grid-gap: 0.5rem;
    margin-bottom: 4px;
  }
  
    
  .container-2-user {
    display: grid;
    width: 100%;
    grid-template-columns: 3fr 0.5fr 2fr 0.5fr 2fr;
    grid-template-areas: "content1 content2 content3 content4 content5";
    grid-gap: 0.5rem;
    margin-bottom: 4px;
  }
      
  .container-3-user {
    display: grid;
    width: 100%;
    grid-template-columns: 3fr  2fr  2fr 0.7fr 0.7fr;
    grid-template-areas: "content1 content2 content3 content4 content5";
    grid-gap: 0.5rem;
    margin-bottom: 4px;
  }

  .container-4-user {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-areas: "content1 content1 content1 content1";
    margin-bottom: 4px;
  }

  .container-5-user{
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr 2fr 2fr ;
    grid-template-areas: "content1 content2 content3 content4";
    grid-gap: 0.5rem;
    margin-bottom: 4px;
  }
  
  .container-6-user {
    display: grid;
    width: 100%;
    grid-template-columns: 4fr 2fr 2fr 2fr ;
    grid-template-areas: "content1 content2 content3 content4";
    grid-gap: 0.5rem;
    margin-bottom: 4px;
  }


  
  .input-box-user input {
    background-color: #fff;
    -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
    -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    color: #222d32;
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    display: inline-block;
    padding: 4px 4px;
    border-radius: 4px;
    border: 1px solid #92989e;
    -moz-transition: border linear 0.2s;
    -o-transition: border linear 0.2s;
    -webkit-transition: border linear 0.2s;
    transition: border linear 0.2s;
    width: 100%;
    border-width: 1px;
    height: 26px;
    margin-bottom: 4px;
  }
  
  .input-box-user input:hover {
    border: 1px solid #222d32;
  }
  
  .input-box-user input:focus {
    border: 2px solid #222d32;
  }
  
  .input-box-user select {
    background-color: #fff;
    -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
    -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    color: #222d32;
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
    border: 1px solid #92989e;
    -moz-transition: border linear 0.2s;
    -o-transition: border linear 0.2s;
    -webkit-transition: border linear 0.2s;
    transition: border linear 0.2s;
    width: 100%;
    border-width: 1px;
    height: 26px;
    margin-bottom: 4px;
  }
  
  .input-box-user select:hover {
    border: 1px solid #222d32;
  }
  
  .input-box-user select:focus {
    border: 2px solid #222d32;
  }
  
  .input-box-user {
    width: 100%;
  }


  .input-box-user span.details {
    display: block;
    font-weight: 500;
    margin-bottom: 2px;
    color: #222d32;
  }
  
  .input-box-user {
    width: 100%;
  }
  
  .input-box-user .selConf {
    display: flex;
  }
  
  .selConf i {
    margin-top: 3px;
    margin-left: 6px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .selConf i:hover {
    opacity: 0.7;
    color: #111827;
  }
  
  .input-box-user textarea {
    background-color: #fff;
    -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
    -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    color: #222d32;
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    display: inline-block;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #92989e;
    -moz-transition: border linear 0.2s;
    -o-transition: border linear 0.2s;
    -webkit-transition: border linear 0.2s;
    transition: border linear 0.2s;
    width: 100%;
    border-width: 1px;
    height: 50px;
    margin-bottom: 4px;

  }
  
  .input-box-user textarea:hover {
    border: 1px solid #222d32;
  }
  .input-box-user textarea:focus {
    border: 2px solid #222d32;
  }
  



  
  #content1 {
    grid-area: content1;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content2 {
    grid-area: content2;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content3 {
    grid-area: content3;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content4 {
    grid-area: content4;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content5 {
    grid-area: content5;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content6 {
    grid-area: content6;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  .divativ{
    margin-bottom: 6px;
  }
  
  .divativ span{
    margin-left: 5px;
    color: #222d32;
    font-size: 15px;
    }

  @media only screen and (max-width: 768px) {
    .container-1-user {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3";
        grid-gap: 0rem;
        margin-bottom: 0px;

    }
    .container-2-user {
        grid-template-columns: 1fr;
        grid-template-areas:
          "content1"
          "content2"
          "content3"
          "content4"
          "content5";
          grid-gap: 0rem;
          margin-bottom: 0px;
      }
      .container-3-user {
        grid-template-columns: 1fr;
        grid-template-areas:
          "content1"
          "content2"
          "content3"
          "content4"
          "content5";
          grid-gap: 0rem;
          margin-bottom: 0px;
      }
      .container-5-user {
        grid-template-columns: 1fr;
        grid-template-areas:
          "content1"
          "content2"
          "content3"
          "content4";
          grid-gap: 0rem;
          margin-bottom: 0px;
      }
      .container-6-user {
        grid-template-columns: 1fr;
        grid-template-areas:
          "content1"
          "content2"
          "content3"
          "content4";
          grid-gap: 0rem;
          margin-bottom: 0px;
      }
      .divativ{
        margin-bottom: 10px;
      }
   .input-box-user{
    margin-bottom: 10px;
   }
   .input-box-user select{
    margin-bottom: 2px;
   }
  }
  
 
  .title-Page {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .title-informacao {
    display: flex;
    width: 100%;
  }
  
  .circle {
    background: #455560;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .size-x {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: #455560;
    margin-right: 10px;
  }
  
  .title-Page .title-informacao h2 {
    color: #455560;
    font-size: 18px;
    margin-top: 2px;
    font-weight: normal;
  }
  
  .linha-Form {
    margin-top: 5px;
    border-top: 1px solid #455560;
  }
  

  .Bt-button {
    display: flex;
    background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #bbb;
    margin-top: 20px;
  }
  
  .Bt-button button {
    font-size: 15px;
    background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
    margin-left: 17px;
    width: 100px;
    border: 1px solid rgb(175, 173, 173);
    cursor: pointer;
    border-radius: 5px;
  }
  
  
  .iconCan {
    color: #2a9e2a;
    font-size: 16px;
  }
  
  .iconcanc{
  color: red;
  }
  
  .Bt-button .btn:hover {
    border: 1px solid #455560;
    opacity: 0.9;
  }
  
  .btn {
    border: none;
    color: #455560;;
    font-size: 18px;
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  

  .div-titulo-cad{
    width: auto;
    display: flex;
    margin-bottom: 15px;

    margin-top: 8px;
}

.ControleIcon-titulo-cad{
    font-size: 15px;
    margin-right: 10px;
}

.titulo-form-visualizar-cad{
  font-size: 10px;
}

.Bt-button-chamado-user {
  display: flex;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  border: 1px solid rgb(175, 173, 173);
  height: 45px;
  border-radius: 5px;
  margin-top: 20px;
  padding: 5px;
  }
  
  .Bt-button-chamado-user button {
  font-size: 15px;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  margin-left: 17px;
  width: 100px;
  border: 1px solid rgb(175, 173, 173);
  cursor: pointer;
  border-radius: 5px;
  }
  
  .Bt-button-chamado-user button:hover {
  border: 1px solid #455560;
  }
  

  