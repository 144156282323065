 /*----------------------------TAB INI------------------------- */

 .content-planejador {
   background: #fff;
 }

 .container-tab {
   display: flex;
   flex-direction: column;
   position: relative;
   width: 100%;
   border: 1px solid rgba(0, 0, 0, 0.274);
 }

 .bloc-tabs {
   display: flex;
   background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);

 }

 .tabs {
   text-align: center;
   width: 50%;
   height: auto;
   background: rgba(128, 128, 128, 0.075);
   cursor: pointer;
   border-bottom: 1px solid rgba(0, 0, 0, 0.274);
   box-sizing: content-box;
   position: relative;
   outline: none;

 }

 .tabs:not(:last-child) {
   border-right: 1px solid rgba(0, 0, 0, 0.274);

 }

 .active-tabs {
   background: white;
   border-bottom: 1px solid transparent;
 }

 .active-tabs h1 {
   color: #139c31
 }

 .active-tabs::before {
   content: "";
   display: block;
   position: absolute;
   top: -2px;
   left: 50%;
   transform: translateX(-50%);
   width: calc(100% + 2px);
   height: 2px;
   background: #222d32;

 }


 .content-tabs {
   flex-grow: 1;

 }

 .content {
   background: white;
   padding-top: 15px;
   padding-bottom: 10px;
   padding-left: 20px;
   padding-right: 20px;
   width: 100%;
   display: none;


 }

 .active-content {
   display: block;

 }

 .containertab-1 {
   display: grid;
   width: 100%;
   grid-template-columns: 0.2fr 1fr 0.2fr;
   grid-template-areas: "content1 content2 content3 ";
   grid-gap: 0.1rem;
 }

 #content-tab1 {
   grid-area: content1;
   border-radius: var(--main-radius);
   padding-top: var(--main-padding);
   flex-wrap: wrap;
   justify-content: space-between;
   font-size: 14px;
   color: #455560;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-bottom: 7px;
 }

 #content-tab2 {
   grid-area: content2;
   border-radius: var(--main-radius);
   padding-top: var(--main-padding);
   flex-wrap: wrap;
   justify-content: space-between;
   font-size: 14px;
   color: #455560;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding-left: 5px;
   padding-right: 5px;

 }

 #content-tab3 {
   grid-area: content3;
   border-radius: var(--main-radius);
   padding-top: var(--main-padding);
   font-size: 14px;
   color: #455560;
   max-height: 560px;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
 }


 .icone-Modal {
   font-size: 20px;
   color: #455560;
   margin-top: 5px;
   margin-left: 5px;
 }


 #content-tab2 h1 {
   font-size: 14px;
   color: #222d32;
 }

 /*----------------------------TAB FIM------------------------- */

 /*----------------------------ÁREA DO INPUT------------------------ */
 .container-1 {
   display: grid;
   width: 100%;
   grid-template-columns: 0.3fr 0.3fr 0.3fr 3fr;
   grid-template-areas: "content1 content2 content3 content4";
   margin-bottom: 7px;
 }

 .container-2 {
   display: grid;
   width: 100%;
   grid-template-columns: 1fr 1fr;
   grid-template-areas: "content1 content2";
   grid-gap: 3rem;
   margin-bottom: 4px;
 }

 .container-3 {
   display: grid;
   width: 100%;
   grid-template-columns: 1fr 1fr 1fr;
   grid-template-areas: "content1 content2 content3";
   grid-gap: 2rem;
 }


 .container-4 {
   display: grid;
   width: 100%;
   grid-template-columns: 1fr 1fr;
   grid-template-areas: "content1 content2";
   grid-gap: 3rem;
   margin-bottom: 10px;
 }


 .container-5 {
   display: grid;
   width: 100%;
   grid-template-columns: 1fr 1fr 1fr;
   grid-template-areas: "content1 content2 content2";
   grid-gap: 3rem;

 }

 .container-5 {
   display: grid;
   width: 100%;
   grid-template-columns: 2fr 2fr 1fr 1fr;
   grid-template-areas: "content1 content2 content3 content4";
   grid-gap: 2rem;

 }

 #content1 {
   grid-area: content1;
   border-radius: var(--main-radius);
   padding-top: var(--main-padding);
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   font-size: 14px;
   color: #455560;
 }

 #content2 {
   grid-area: content2;
   border-radius: var(--main-radius);
   padding-top: var(--main-padding);
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   font-size: 14px;
   color: #455560;

 }

 #content3 {
   grid-area: content3;
   border-radius: var(--main-radius);
   padding-top: var(--main-padding);
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   font-size: 14px;
   color: #455560;
 }

 #content4 {
   grid-area: content4;
   border-radius: var(--main-radius);
   padding-top: var(--main-padding);
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   font-size: 14px;
   color: #455560;
 }

 #content-Nome4 {
   grid-area: content4;
   border-radius: var(--main-radius);
   padding-top: var(--main-padding);
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   font-size: 14px;
   color: #455560;
   padding-left: 20px;
 }


 #content4 {
   grid-area: content4;
   border-radius: var(--main-radius);
   padding-top: var(--main-padding);
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   font-size: 14px;
   color: #455560;
 }

 /*----------------------------FIM ÁREA DO INPUT------------------------ */

 /*----------------------------INPUT CHECKBOX------------------------ */

 .input-ct span {
   margin-left: 10px;
   margin-top: 2px;
   font-size: 14px;
 }

 .input-box-checkbox2 span.details {
   display: block;
   font-weight: 500;
   margin-bottom: 5px;
   color: #222d32;
   font-size: 14px;
 }

 .input-box span.details-list {
   display: block;
   font-weight: bold;
   color: #222d32;
   font-size: 12px;
 }

 .input-box span.details-list-esc {
   display: block;
   font-weight: bold;
   color: #222d32;
   margin-left: 3px;
   font-size: 12px;
 }

 /*----------------------------INPUT------------------------ */
 .input-box input {
   background-color: #fff;
   -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
   -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
   box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
   color: #222d32;
   font-size: 14px;
   font-weight: 500;
   box-sizing: border-box;
   display: inline-block;
   padding: 4px 4px;
   border-radius: 4px;
   border: 1px solid #92989e;
   -moz-transition: border linear 0.2s;
   -o-transition: border linear 0.2s;
   -webkit-transition: border linear 0.2s;
   transition: border linear 0.2s;
   width: 100%;
   border-width: 1px;
   height: 26px;
   margin-bottom: 4px;
 }

 .input-box input:focus {
   border: 2px solid #222d32;
 }

 .input-box input:hover {
   border: 1px solid #222d32;
 }

 .input-box {
   width: 100%;
 }

 .input-box span.details {
   display: block;
   font-weight: bold;
   color: #222d32;
   margin-bottom: 4px;
   font-size: 12px;
 }





 .input-c input {
   height: 18px;
   width: 18px;
   outline: none;
   font-size: 14px;
   border-radius: 5px;
   padding-left: 5px;
   padding-right: 2px;
   border: 1px solid rgb(164, 163, 163);
   transition: all 0.3s ease;
   color: #222d32;
 }

 .input-check {
   height: 18px;
   width: 18px;
   outline: none;
   font-size: 14px;
   border-radius: 5px;
   padding-left: 2px;
   padding-right: 2px;
   border: 1px solid rgb(164, 163, 163);
   transition: all 0.3s ease;
   color: #222d32;

 }

 .input-c {
   display: flex;
   padding-bottom: 6px;

 }

 .input-ct {
   display: flex;

 }

 .input-box3 {
   width: 100%;
   margin-top: 10px;
 }

 .input-box3 span.details {
   display: block;
   font-weight: bold;
   margin-top: 1px;
   margin-left: 8px;
   color: #222d32;
   margin-bottom: 4px;
   font-size: 12px;
 }

 /*--------------------------------------------------------*/
 .input-box select {
   background-color: #fff;
   -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
   -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
   box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
   color: #222d32;
   font-size: 14px;
   font-weight: 500;
   box-sizing: border-box;
   display: inline-block;
   border-radius: 4px;
   border: 1px solid #92989e;
   -moz-transition: border linear 0.2s;
   -o-transition: border linear 0.2s;
   -webkit-transition: border linear 0.2s;
   transition: border linear 0.2s;
   width: 100%;
   border-width: 1px;
   height: 26px;
   margin-bottom: 4px;
 }

 .input-box select:hover {
   border: 1px solid #222d32;
 }

 .input-box select:focus {
   border: 2px solid #222d32;
 }

 /*------------------------------------------------------- */


 .user-details {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   font-size: 14px;
   color: #455560;
   margin-bottom: 4px;
 }

 .user-details2 {
   display: flex;
   font-size: 14px;
   color: #455560;
 }


 .input-box2 {
   width: calc(100% / 2 - 24px);
 }

 .input-box3 {
   width: calc(100% / 3 - 20px);
 }

 .input-box4 {
   margin-right: 12px;

 }

 .input-box-checkbox2 {
   width: calc(100% / 3);
   text-align: center;
 }


 .input-box2 span.details {
   display: block;
   font-weight: bold;
   color: #222d32;
   margin-bottom: 4px;
   font-size: 12px;
 }



 .input-descr {
   background-color: #fff;
   -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
   -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
   box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
   color: #222d32;
   font-size: 14px;
   font-weight: 500;
   box-sizing: border-box;
   display: inline-block;
   padding: 4px 4px;
   border-radius: 4px;
   border: 1px solid #92989e;
   -moz-transition: border linear 0.2s;
   -o-transition: border linear 0.2s;
   -webkit-transition: border linear 0.2s;
   transition: border linear 0.2s;
   width: 100%;
   border-width: 1px;
   height: 26px;
   margin-bottom: 4px;
 }

 .input-descr:hover {
   border: 1px solid #222d32;
 }

 .input-descr:focus {
   border: 2px solid #222d32;
 }


 .input-check input {
   height: 25px;
   width: 100%;
   outline: none;
   font-size: 14px;
   border-radius: 5px;
   padding-left: 5px;
   padding-right: 10px;
   border: 1px solid #455560;
   transition: all 0.3s ease;
   color: #455560;

 }

 /*--------------------------icone - butão------------------------- */
 .icone-busca-agen {
   padding: 2px;
   background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
   text-align: center;
   color: #000000;
   border: 1px solid #bbb;
   border-radius: 5px;
   height: 30px;
   display: flex;
   cursor: pointer;
   justify-content: center;
   width: 100%;
   margin-top: 18px;
 }

 .icone-busca-agen .h1-busca {
   font-size: 14px;
   margin-top: 4px;
   color: #455560;
   margin-left: 4px;

 }

 .icone-busca-agen:hover {
   opacity: 0.7;
   border: 1px solid #111827;
 }

 .Bt-button-planejador {
   display: flex;
   background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
   border: 1px solid rgb(175, 173, 173);
   height: 45px;
   border-radius: 5px;

   padding: 5px;
   margin-left: 20px;
   margin-right: 20px;
   margin-bottom: 15px;
 }

 .Bt-button-planejador button {
   font-size: 15px;
   background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
   margin-left: 17px;
   width: 100px;
   border: 1px solid rgb(175, 173, 173);
   cursor: pointer;
   border-radius: 5px;
 }

 .Bt-button-planejador button:hover {
   border: 1px solid #455560;
 }



 /*------------------------------------------------------- */
 @media only screen and (max-width: 768px) {


   .container-2 {
     grid-template-columns: 1fr;
     grid-template-areas:
       "content1"
       "content2";
     grid-gap: 0rem;
     margin-bottom: 5px;
   }

   .input-box {
     margin-bottom: 10px;
   }

   .container-3 {
     grid-template-columns: 1fr;
     grid-template-areas:
       "content1"
       "content2"
       "content3";
     grid-gap: 0rem;
     margin-bottom: 5px;
   }

   .container-5 {
     grid-template-columns: 1fr;
     grid-template-areas:
       "content1"
       "content2"
       "content3"
       "content4";
     grid-gap: 0rem;
     margin-bottom: 5px;
   }

   .input-box2 {
     margin-bottom: 12px;
     width: calc(100% / 1);
   }

   .input-box3 {
     margin-bottom: 12px;
     width: calc(100% / 1);
   }

   .input-box4 {}
 }

 /*--------------------------DualListBox----------------------------- */
