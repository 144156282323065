.Content{
    display: flex;
   width: 100%;
}

.container-ini{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    grid-template-areas: "content1 content2 content3 content4";
    grid-gap: 2rem;
  }

.container-ini-2{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "content1 content2";
  grid-gap: 2rem;
  margin-top: 20px; 
}


#content1-ini {
  grid-area: content1;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
  width: 100%;
  
}


#content2-ini {
  grid-area: content2;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}


  #content1 {
    grid-area: content1;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  

  #content2 {
    grid-area: content2;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content3 {
    grid-area: content3;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content4 {
    grid-area: content4;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  

    
  @media only screen and (max-width: 768px) {
    
    .container-ini {
       grid-template-columns: 1fr;
       grid-template-areas:
         "content1"
         "content2"
         "content3"
         "content4";
         grid-gap: 0rem;
         margin-bottom: 0px;
     }
   
}


.container {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 770px) {
  .container {
    width: 100%;
  }
}