.input-box-amb select {
    background-color: #fff;
    -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
    -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    color: #222d32;
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
    border: 1px solid #92989e;
    -moz-transition: border linear 0.2s;
    -o-transition: border linear 0.2s;
    -webkit-transition: border linear 0.2s;
    transition: border linear 0.2s;
    width: 182px;
    margin-left: 10px;
    border-width: 1px;
    height: 26px;
    margin-bottom: 4px;
    margin-top: -2px;
  }
  
  .input-box-amb select:hover {
    border: 1px solid #222d32;
  }
  
  .input-box-amb select:focus {
    border: 2px solid #222d32;
  }