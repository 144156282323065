.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;  
  justify-content: center; 
  background-color: rgba(0,0,0,0.5);
}

.modal-wrapper {
  margin-left: 16px ;
  margin-right: 16px ;

}

.ModalHeader{
margin-left: 10px;
}

.modal-conf{
  z-index: 100;
  background: white;
  position: relative;
  margin:30px  auto;
  max-width: 600px;
  padding: 8px;
  border-radius: 8px;
  height: auto;
  margin-top: 90px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 8px 0;
  font-weight: bold;

}

.icone-modal {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-right: 20px;
}

.icone-modal:hover {
  color: red;
}

.modal-body {
  padding: 8px 0;
  overflow-y:auto;
  max-height: 470px;
  
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;  
}
